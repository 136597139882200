<template>
  <div class="container">
    <div class="container-header">
      <div class="container-header__title">新增/编辑权益</div>
      <div class="container-header__tips">为必填</div>
    </div>
    <div class="body">
      <el-form ref="form" label-width="100px" size="small" :model="ruleForm" :disabled="disabled">
        <div class="body__title">基本信息</div>
        <el-form-item label="权益ID">
          <el-input
            placeholder="系统自动生成"
            :value="ruleForm.rightsId"
            disabled
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="权益名称"
          prop="rightsName"
          :rules="[{ required: true, message: '请输入权益名称', trigger: 'blur' }]"
        >
          <el-input
            v-model="ruleForm.rightsName"
            placeholder="请输入权益名称"
            maxlength="7"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="权益类别"
          prop="rightsCategoryId"
          :rules="[{ required: true, message: '请选择权益类别', trigger: 'change' }]"
        >
          <el-select
            v-model="ruleForm.rightsCategoryId"
            style="width: 240px"
            placeholder="请选择权益类别"
          >
            <el-option
              v-for="(item, index) in rightsList"
              :key="index"
              :label="item.rightsCategoryName"
              :value="item.rightsCategoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="优惠类型"
          prop="discountCategoryId"
          :rules="[{ required: true, message: '请选择优惠类型', trigger: 'change' }]"
        >
          <el-select
            v-model="ruleForm.discountCategoryId"
            style="width: 240px"
            placeholder="请选择优惠类型"
          >
            <el-option
              v-for="(item, index) in discountsList"
              :key="index"
              :label="item.rightsCategoryName"
              :value="item.rightsCategoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="发放方式"
          prop="issueType"
          :rules="[{ required: true, message: '请选择发放方式', trigger: 'change' }]"
        >
          <el-radio-group v-model="ruleForm.issueType">
            <el-radio :label="1">自主领取</el-radio>
            <el-radio :label="2">系统发放</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="发放节点"
          prop="issueNode"
          :rules="[{ required: true, message: '请选择发放节点', trigger: 'change' }]"
        >
          <el-radio-group v-model="ruleForm.issueNode" @change="issueNodeChange">
            <el-radio :label="1">生日当天</el-radio>
            <el-radio :label="2">达到等级</el-radio>
            <el-radio :label="3">
              <span style="margin-right: 10px">固定时间</span>
              <el-date-picker
                v-model="ruleForm.issueTime"
                type="datetime"
                style="width: 200px"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="ruleForm.issueNode != 3"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="权益icon">
          <div class="upload-box">
            <ty-upload-image
              :multiple="false"
              :size="1"
              accept=".jpg,.png,.jpeg,.gif"
              :disabled="disabled"
              :files="ruleForm.iconUrl"
              @upload="iconUrlUpload"
            ></ty-upload-image>
            <div class="upload-tips">
              <div>仅支持jpg、jpeg、png、gif格式</div>
              <div>建议尺寸88×88px，不超过1MB</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="详情页主图"
          prop="coverUrl"
          :rules="[{ required: true, message: '请上传详情页主图', trigger: 'change' }]"
        >
          <div class="upload-box">
            <ty-upload-image
              :multiple="false"
              :size="2"
              :disabled="disabled"
              :files="ruleForm.coverUrl"
              @upload="coverUrlUpload"
            ></ty-upload-image>
            <div class="upload-tips">
              <div>仅支持jpg、jpeg、png格式</div>
              <div>建议尺寸1080×988px不超过2M</div>
            </div>
          </div>
        </el-form-item>
        <div v-show="ruleForm.discountCategoryId == 4" class="body__title">权益卡信息</div>
        <el-form-item
          v-show="ruleForm.discountCategoryId == 4"
          label="适用范围"
          prop="applyProduct"
          :rules="[{ required: true, message: '请选择适用范围', trigger: 'change' }]"
        >
          <el-radio-group v-model="ruleForm.applyProduct" @change="applyProductChange">
            <el-radio :label="2">部分产品</el-radio>
            <el-radio :label="1">全部产品</el-radio>
          </el-radio-group>
          <div v-show="ruleForm.applyProduct == 2" class="product-tabel">
            <el-button size="mini" type="primary" @click="showSelectProduct">选择产品</el-button>
            <el-table size="small" :data="ruleForm.applyProductList || []" height="300">
              <el-table-column prop="productCode" label="产品ID" min-width="70"> </el-table-column>
              <el-table-column prop="productName" label="产品名称" min-width="120">
              </el-table-column>
              <el-table-column prop="categoryName" label="产品分类" min-width="80">
              </el-table-column>
              <el-table-column min-width="50" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="productDel(scope.row)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item
          label="失效时间"
          prop="invalidType"
          :rules="[{ required: true, message: '请选择失效时间', trigger: 'change' }]"
        >
          <el-radio-group v-model="ruleForm.invalidType" @change="invalidTypeChange">
            <el-radio :label="1"
              >领取后<el-input-number
                v-model="ruleForm.invalidDay"
                controls-position="right"
                class="input-number"
                style="width: 90px"
                :min="1"
                :disabled="ruleForm.invalidType == 2"
              ></el-input-number>
              天</el-radio
            >
            <el-radio :label="2">
              固定时间
              <el-date-picker
                v-model="ruleForm.invalidTime"
                type="datetime"
                placeholder="选择日期时间"
                class="input-number"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 180px"
                :disabled="ruleForm.invalidType == 1"
              >
              </el-date-picker>
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="body__title">权益的领取及使用规则</div>
        <el-form-item
          label="权益内容"
          prop="rightsContent"
          :rules="[{ required: true, message: '请输入权益内容', trigger: 'blur' }]"
        >
          <el-input
            v-model="ruleForm.rightsContent"
            type="textarea"
            placeholder="请输入"
            maxlength="300"
            :autosize="{ minRows: 4, maxRows: 12 }"
            style="width: 500px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="使用详情"
          prop="useDetail"
          :rules="[{ required: true, message: '请输入使用详情', trigger: 'blur' }]"
        >
          <el-input
            v-model="ruleForm.useDetail"
            type="textarea"
            placeholder="请输入使用规则及使用方式等信息"
            :autosize="{ minRows: 4, maxRows: 12 }"
            maxlength="300"
            style="width: 500px"
          >
          </el-input>
        </el-form-item>
        <div class="footer">
          <el-button v-if="!disabled" type="primary" size="small" @click="onSubmit"
            >提 交</el-button
          >
          <el-button size="small" :disabled="false" @click="onClose">取 消</el-button>
        </div>
      </el-form>
    </div>
    <Product
      ref="productList"
      :dialog-visible.sync="dialogVisible2"
      :product-type="productType"
      :multiple-selection.sync="ruleForm.applyProductList"
    />
  </div>
</template>

<script>
import tyUploadImage from '@/components/ty-upload-image/index.vue'
import Product from '@/views/sourceMaterial/cardVoucher/popup/product.vue'
import {
  findListByCondition,
  interestAdd,
  findInterestById,
  interestUpdateById,
} from '@/api/interest.js'
export default {
  components: { tyUploadImage, Product },
  data() {
    return {
      dialogVisible2: false,
      productType: '3',
      ruleForm: {
        rightsId: '',
        rightsName: '',
        applyProduct: 1,
        applyProductList: [],
        invalidType: 1,
        invalidDay: 1,
        issueNode: 2,
        issueType: 1,
        issueTime: null,
        invalidTime: null,
        discountCategoryId: '',
        rightsCategoryId: '',
      },
      rightsList: [],
      discountsList: [],
      rightsId: '',
    }
  },
  created() {
    this.rightsId = this.$route.query.rightsId
    this.disabled = !!this.$route.query.disabled
    // 权益类型
    this.getOptions(1).then((res) => (this.rightsList = res.data))
    // 优惠类型
    this.getOptions(2).then((res) => (this.discountsList = res.data))
    this.rightsId && this.getDetail()
  },
  methods: {
    getDetail() {
      findInterestById({
        data: this.rightsId,
      }).then((res) => {
        console.log(res)
        if (res.data && !res.data.applyProductList) {
          res.data.applyProductList = []
        }

        this.ruleForm = res.data
      })
    },
    async getOptions(rightsCategoryTypeId) {
      return await findListByCondition({
        data: {
          rightsCategoryTypeId,
          isDisable: 0,
        },
      })
    },
    showSelectProduct() {
      this.dialogVisible2 = true
    },
    iconUrlUpload(e) {
      this.ruleForm.iconUrl = e
    },
    coverUrlUpload(e) {
      this.ruleForm.coverUrl = e
    },
    issueNodeChange(e) {
      if (e !== 3) this.ruleForm.issueTime = null
    },
    invalidTypeChange() {
      this.ruleForm.invalidDay = null
      this.ruleForm.invalidTime = null
    },
    applyProductChange() {
      this.ruleForm.applyProductList = []
    },
    productDel(row) {
      console.log(row)
      this.ruleForm.applyProductList = this.ruleForm.applyProductList.filter(
        (e) => e.productId !== row.productId,
      )
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        if (this.ruleForm.issueNode === 3 && !this.ruleForm.issueTime) {
          return this.$message('请选择固定时间')
        }

        if (this.ruleForm.applyProduct === 2 && !this.ruleForm.applyProductList.length) {
          return this.$message('请选择适用产品')
        }

        if (this.ruleForm.invalidType === 2 && !this.ruleForm.invalidTime) {
          return this.$message('请选择失效时间')
        }

        const func = this.rightsId ? interestUpdateById : interestAdd
        func({
          data: this.ruleForm,
        })
          .then((res) => {
            console.log(res)
            this.$message.success(this.rightsId ? '更新成功~' : '新增成功~')
            this.onClose()
          })
          .catch((e) => {
            console.log(e)
          })
      })
    },
    onClose() {
      this.$router.go(-1)
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
}
.container-header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;

  box-shadow: inset 0px -1px 0px 1px #ededed;
}
.container-header__title {
  color: #111111;
  font-size: 16px;
  font-weight: 500;
}
.container-header__tips {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  &::before {
    content: '*';
    color: #e86247;
  }
}
.body {
  padding: 20px 24px;
  box-sizing: border-box;
}
.body__title {
  color: #111111;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 3px;
    height: 22px;
    background-color: #363f47;
    margin-right: 8px;
    display: inline-block;
    border-radius: 3px 3px 0 0;
  }
}
.input-number {
  margin: 0 10px;
}
.product-tabel {
  width: 700px;
  margin-top: 20px;
}
.footer {
  text-align: center;
  margin: 50px 0;
}
.upload-box {
  display: flex;
  align-items: center;
}
.upload-tips {
  margin-left: 10px;
  color: #999;
  font-size: 14px;
}
</style>
